import * as React from 'react';

import { FunctionField, TextField, useRecordContext } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { TableBody, TableRowWrapper } from 'shared/mui/Table';
import { DateTimeField, SingleFieldList } from 'shared/react-admin/Fields';
import { ReferenceArrayField, ReferenceField } from 'shared/react-admin/ReferenceFields';
import { BankOperation, Currency } from 'types';

import { TableRowUnitedWrapper } from '../../../../shared/mui/Table/TableRowUnitedWrapper/TableRowUnitedWrapper';
import {
  TableCellStyled,
  TableRowCommentsStyled,
} from '../../../../shared/widgets/CommissionsShowTable/CommissionsShowTable.styled';
import { formatCurrency } from '../../../../utils';
import { BankOperationsListComment } from '../BankOperationsListNew/BankOperationsListComment/BankOperationsListComment';
import { BankOperationStatusChipField } from '../components/BankOperationStatusChipField/BankOperationStatusChipField';

const HEADER_SX = { fontWeight: 'initial', fontSize: '16px', paddingTop: '24px' };

export const BankOperationsShowTableNew: React.FC = () => {
  const record = useRecordContext<BankOperation>();
  return (
    <CardTable sx={{ tableLayout: 'fixed', borderTop: 'none' }}>
      <TableBody>
        <TableRowWrapper headerStyle={HEADER_SX} title="Основные настройки:" />
        <TableRowWrapper title="ID">
          <TextField source="originId" />
        </TableRowWrapper>
        <TableRowWrapper title="Мерчант">
          <ReferenceField link={false} reference="merchants" source="merchant">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Провайдер">
          <ReferenceField link={false} reference="providers" source="provider">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Направление">
          <ReferenceField link={false} reference="directions" source="direction">
            <TextField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Тип операции">
          <ReferenceField link={false} reference="bank_operation_types" source="type">
            <TextField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Статус заявки">
          <ReferenceField link={false} reference="bank_operation_statuses" source="status">
            <BankOperationStatusChipField source="description" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Счет мерчанта">
          <ReferenceField link={false} reference="merchant_accounts" source="merchantAccount">
            <TextField source="name" />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Сумма">
          <FunctionField
            render={({ amountPenny }: BankOperation) => formatCurrency(Number(amountPenny))}
          />
        </TableRowWrapper>
        <TableRowWrapper title="Валюта">
          <ReferenceField label="Валюта" link={false} reference="currencies" source="currency">
            <FunctionField render={({ alphaCode, name }: Currency) => `${alphaCode}, ${name}`} />
          </ReferenceField>
        </TableRowWrapper>
        <TableRowWrapper title="Дата проведения">
          <DateTimeField source="dateOfOperation" />
        </TableRowWrapper>
        <TableRowUnitedWrapper title="Описание">
          <TextField emptyText="—" source="description" sx={{ wordBreak: 'break-word' }} />
        </TableRowUnitedWrapper>
        {record.comments.length > 0 && (
          <TableRowWrapper headerStyle={HEADER_SX} title="Комментарии:" />
        )}
        {record.comments.length > 0 && (
          <TableRowCommentsStyled>
            <TableCellStyled colSpan={2}>
              <ReferenceArrayField
                reference="bank_operation_comments"
                sortable={false}
                source="comments"
              >
                <SingleFieldList linkType={false}>
                  <BankOperationsListComment />
                </SingleFieldList>
              </ReferenceArrayField>
            </TableCellStyled>
          </TableRowCommentsStyled>
        )}
        <TableRowWrapper headerStyle={HEADER_SX} title="История:" />
        <TableRowWrapper title="Дата создания">
          <DateTimeField source="createdAt" />
        </TableRowWrapper>
        <TableRowWrapper title="Дата изменения">
          <DateTimeField source="updatedAt" />
        </TableRowWrapper>
      </TableBody>
    </CardTable>
  );
};

import { CONVERSION_RATE_TYPES_VALUES, ConversionRateTypes } from 'constants/conversionTypes';

import { differenceInDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { RaRecord, required } from 'react-admin';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { DateRangeTimezonePicker } from 'shared/mui/DatePicker/DateRangeTimezonePicker/DateRangeTimezonePicker';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

registerLocale('ru', ru);

export const BalanceReportNewFilter: React.FC = () => {
  const { watch, control, setValue } = useFormContext();

  const [companies, merchants, providers] = watch(['companies', 'merchants', 'providers']);

  return (
    <>
      <Alert severity="warning">
        Период формирования отчета не должен превышать 1825 календарных дней!
      </Alert>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
          <DateRangeTimezonePicker
            error={!!errors.appliedDate}
            helperText={fieldState?.error?.message}
            label="Период отчета от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            required
            value={value}
          />
        )}
        rules={{
          validate: {
            required: (value) => (!!value[0] && !!value[1]) || 'Обязательно для заполнения',
            periodError: (value) => {
              const [from, to] = value;
              const intervalInDays = differenceInDays(to, from);
              return intervalInDays <= 1825;
            },
          },
        }}
      />
      <SelectInputShrink
        choices={CONVERSION_RATE_TYPES_VALUES}
        defaultValue={ConversionRateTypes.Actual}
        helperText={false}
        label="Курс конвертации"
        name="conversionRateType"
        optionText="label"
        source="conversionRateType"
        validate={required()}
        variant="outlined"
      />
      <ReferenceInput
        filter={{ active: true }}
        name="currency"
        perPage={1000}
        reference="currencies"
        resettable
        resource="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="currency"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта отчета"
          name="currency"
          optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
          optionValue="alphaCode"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={{ archive: false }}
        isFilter={true}
        name="companies"
        perPage={200}
        reference="companies"
        resettable
        resource="companies"
        source="companies"
      >
        <AutocompleteMultiselectInputShrink
          clearOnBlur={false}
          helperText={false}
          label="Компания"
          limitTags={1}
          name="companies"
          onChange={() => {
            setValue('merchants', []);
            setValue('providers', []);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!companies?.length}
        filter={{ companies, exists: { merchantAccounts: true }, archive: false }}
        isFilter={true}
        name="merchants"
        perPage={200}
        reference="merchants"
        resettable
        resource="merchants"
        source="merchants"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!companies?.length}
          helperText={false}
          label="Мерчант"
          limitTags={1}
          name="merchants"
          onChange={() => {
            setValue('providers', []);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchants?.length}
        filter={{ merchantAccounts: { merchants } }}
        isFilter={true}
        name="providers"
        perPage={200}
        reference="providers"
        resettable
        resource="providers"
        source="providers"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!merchants?.length}
          helperText={false}
          label="Провайдер"
          limitTags={1}
          name="providers"
          onChange={() => {
            setValue('merchantAccounts', []);
          }}
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!providers?.length}
        filter={{ merchants, provider: providers }}
        isFilter={true}
        name="merchantAccounts"
        perPage={200}
        reference="merchant_accounts"
        resettable
        resource="merchant_accounts"
        source="merchantAccounts"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!providers?.length}
          helperText={false}
          label="Счет мерчанта"
          limitTags={1}
          name="merchantAccounts"
          optionText="name"
        />
      </ReferenceArrayInput>
    </>
  );
};

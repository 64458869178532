import DoneIcon from '@mui/icons-material/Done';
import { useShowContext } from 'react-admin';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { Order } from 'types';

export const OrderShowCommonInfoMainMerchantIdField = (): JSX.Element => {
  const { record: order } = useShowContext<Order>();
  const isMerchantIdEnabled = order?.orderExtendedInfo?.merchant_id_enabled;

  return (
    <FunctionField
      render={() => (
        <Stack direction="row" gap="8px">
          <Typography variant="body2">{order?.merchantReference}</Typography>
          {isMerchantIdEnabled && <DoneIcon color="success" sx={{ fontSize: '18px' }} />}
        </Stack>
      )}
      sortable={false}
      source="originId"
    />
  );
};

import { AppReferenceField, AppReferenceOneField } from 'components/Common';
import { useRecordModal } from 'hooks/useRecordModal';
import { FunctionField, TextField } from 'react-admin';
import { CardTable } from 'shared/mui/Card/CardTable/CardTable';
import { ColorChip } from 'shared/mui/Chips/ColorChip/ColorChip';
import { TableBody, TableCell, TableCellHeader, TableRow } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import { DateTimeField } from 'shared/react-admin/Fields';
import { ReferenceField } from 'shared/react-admin/ReferenceFields';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { BankOperationStatus, MerchantAccount } from 'types';

export const BankOperationsShowTable = () => {
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  return (
    <CardTable sx={{ tableLayout: 'fixed' }}>
      <TableBody>
        <TableRow>
          <TableCellHeader title="ID" width={165} />
          <TableCell>
            <TextField source="originId" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Дата проведения" />
          <TableCell>
            <DateTimeField source="dateOfOperation" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Мерчант" />
          <TableCell>
            <AppReferenceOneField link="show" reference="merchants" source="merchant" target="id">
              <TextField source="name" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Счет мерчанта" />
          <TableCell>
            <ReferenceField reference="merchant_accounts" source="merchantAccount">
              <FunctionField
                render={(record: MerchantAccount) => (
                  <Typography
                    color="secondary"
                    onClick={() => handleOpenMerchantAccountModal(record)}
                    sx={{ color: appColors.primary.main, cursor: 'pointer' }}
                    variant="body2"
                  >
                    {record.name}
                  </Typography>
                )}
              />
            </ReferenceField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Направление" />
          <TableCell>
            <AppReferenceOneField reference="directions" source="direction" target="id">
              <TextField source="description" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Тип операции" />
          <TableCell>
            <AppReferenceOneField reference="bank_operation_types" source="type" target="id">
              <TextField source="name" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Статус заявки" />
          <TableCell>
            <AppReferenceField reference="bank_operation_statuses" source="status">
              <FunctionField
                render={(record: BankOperationStatus) => {
                  return (
                    <ColorChip
                      iconColor={appColors.bankOperationStatus[record?.value]}
                      label={record?.description}
                    />
                  );
                }}
              />
            </AppReferenceField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Сумма" />
          <TableCell>
            <TextField source="amountPenny" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Валюта" />
          <TableCell>
            <AppReferenceOneField
              label="Валюта"
              link={false}
              reference="currencies"
              source="currency"
              target="id"
            >
              <TextField source="alphaCode" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Шлюз" />
          <TableCell>
            <AppReferenceOneField reference="gateways" source="gateway" target="id">
              <TextField source="name" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Провайдер" />
          <TableCell>
            <AppReferenceOneField reference="providers" source="provider" target="id">
              <TextField source="name" />
            </AppReferenceOneField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCellHeader title="Комментарий" />
          <TableCell>
            <TextField emptyText="—" source="comment" sx={{ wordBreak: 'break-word' }} />
          </TableCell>
        </TableRow>
      </TableBody>
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </CardTable>
  );
};

import { EMPTY_FIELD } from 'constants/emptyField';

import { Box } from 'shared/mui/Box';
import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { CumulativeReport } from 'types';
import { formatCurrency } from 'utils';

import { ReactComponent as ArrowDown } from '../../../../../../images/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../../../images/arrowUp.svg';
import { COLORS } from '../constants/colors';

type Props = {
  valueKey: keyof CumulativeReport;
} & Omit<FunctionFieldProps, 'render'>;

export const CumulativeReportDatagridTotalField: React.FC<Props> = ({ label, valueKey }) => (
  <FunctionField
    label={label}
    render={(record: CumulativeReport) => {
      if (Number(record[valueKey]) === 0) {
        return EMPTY_FIELD;
      }
      const isNegative = record?.totalAmount[0] === '-';

      return (
        <Stack direction="column">
          <Box>
            {isNegative ? <ArrowDown /> : <ArrowUp />}
            <span style={{ color: isNegative ? COLORS.red : COLORS.green }}>
              {' '}
              {formatCurrency(Number(record[valueKey]), 'en')}
            </span>
          </Box>
        </Stack>
      );
    }}
  />
);

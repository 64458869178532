import { Button } from 'shared/mui/Buttons';
import { Typography } from 'shared/mui/Typography';

import { AlertStyled, BoxStyled } from './OrdersReportListShortReportAlertStyled';

type Props = {
  isFetching: boolean;
  getSummary: VoidFunction;
};

export const OrdersReportListShortReportAlertPreview: React.FC<Props> = ({
  isFetching,
  getSummary,
}) => (
  <AlertStyled severity="warning" sx={{ marginBottom: '16px' }}>
    <BoxStyled>
      <Typography variant="body2">
        Сформировать сводку по неисполненным транзакциям за указанный период создания?
      </Typography>
      <Button
        color="inherit"
        isLoading={isFetching}
        label="Сформировать"
        onClick={getSummary}
        size="small"
        sx={{
          lineHeight: 1,
        }}
        variant="text"
      />
    </BoxStyled>
  </AlertStyled>
);

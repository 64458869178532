import { LocalStorageCachedKeys } from 'constants/localStorageStoreKeys';

// eslint-disable-next-line import/order
import { useContext, useEffect } from 'react';

import './styles/global.scss';
import './styles/reactJsonViewCompare.scss';
import './styles/datePickerOverrides.scss';
import * as React from 'react';

import { HydraAdmin, ResourceGuesser, useIntrospection } from '@api-platform/admin';
import { useFlag } from '@unleash/proxy-client-react';
import { BalanceReportList } from 'components/Finance/BalanceReport';
import {
  BankOperationsCreate,
  BankOperationsEdit,
  BankOperationsShow,
  BankOperationsList,
} from 'components/Finance/BankOperations';
import { CumulativeReportListNew } from 'components/Finance/CumulativeReport/CumulativeReportListNew';
import { OrdersReportListNew } from 'components/Finance/OrdersReport/OrdersReportList/OrdersReportListNew';
import {
  WorkTeamsCreate,
  WorkTeamsEdit,
  WorkTeamsList,
  WorkTeamsShow,
} from 'components/Finance/WorkTeams';
import { AppLoginPage } from 'components/Login';
import { MerchantSpacesUsersEdit } from 'components/MerchantSpacesUsers/MerchantSpacesUsersEdit/MerchantSpacesUsersEdit';
import { OrderAdjustmentsList } from 'components/OrderAdjustments/OrderAdjustmentsList';
import { UserActionsList, UserActionsShow } from 'components/UserActions';
import { API_PATH } from 'config/entrypoint';
import { localStorageStore } from 'config/localStorageStore';
import { authProvider } from 'providers/authProvider';
import { CustomRoutes, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import { BrowserRouter, Route } from 'react-router-dom';
import { Loading } from 'shared/react-admin/Loading/Loading/Loading';
import { Permission } from 'types';

import {
  ApiClientsCreate,
  ApiClientsEdit,
  ApiClientsList,
  ApiClientsShow,
} from './components/ApiClients';
import {
  CommissionsCreate as CommissionsCreateOld,
  CommissionsEdit as CommissionsEditOld,
  CommissionsShow as CommissionsShowOld,
} from './components/Commissions';
import { CommissionsEdit } from './components/Commissions/CommissionEditNew/CommissionsEdit';
import { CommissionsCreate } from './components/Commissions/CommissionsCreateNew/CommissionsCreate';
import { CommissionsCreateNewVersion } from './components/Commissions/CommissionsCreateNewVersion/CommissionsCreateNewVersion';
import { CommissionsList as CommissionsListOld } from './components/Commissions/CommissionsList/CommissionsList';
import { CommissionsList } from './components/Commissions/CommissionsListNew/CommissionsList';
import { CommissionsShow } from './components/Commissions/CommissionsShowNew/CommissionsShow';
import { AppOrdersBulkUpdate } from './components/Common';
import { DialogResource, DialogResourceProps } from './components/Common/Resource/DialogResource';
import { PageResource } from './components/Common/Resource/PageResource';
import { PageResourceGuesser } from './components/Common/Resource/PageResourceGuesser';
import {
  CompaniesCreate,
  CompaniesEdit,
  CompaniesList,
  CompaniesShow,
} from './components/Companies';
import { BankOperationsCreateNew } from './components/Finance/BankOperations/BankOperationsCreateNew/BankOperationsCreateNew';
import { BankOperationsEditNew } from './components/Finance/BankOperations/BankOperationsEditNew/BankOperationsEditNew';
import { BankOperationsListNew } from './components/Finance/BankOperations/BankOperationsListNew/BankOperationsListNew';
import { BankOperationsShowNew } from './components/Finance/BankOperations/BankOperationsShowNew/BankOperationsShowNew';
import {
  ProviderAccountsCreate,
  ProviderAccountsEdit,
  ProviderAccountsList,
  ProviderAccountsShow,
} from './components/Finance/ProviderAccounts';
import {
  GatewayDeactivationReasonsList,
  GatewayDeactivationReasonsCreate,
  GatewayDeactivationReasonsEdit,
  GatewayDeactivationReasonsShow,
} from './components/GatewayDeactivationReasons';
import {
  GatewayRulesCreate,
  GatewayRulesEdit,
  GatewayRulesShow,
  GatewayRulesList,
} from './components/GatewayRules';
import { GatewaysCreate, GatewaysEdit, GatewaysList, GatewaysShow } from './components/Gateways';
import { LimitsCreate, LimitsEdit, LimitsList, LimitsShow } from './components/Limits';
import {
  MerchantsCreate,
  MerchantsDelete,
  MerchantsEdit,
  MerchantsList,
  MerchantsShow,
} from './components/Merchants';
import { MerchantSpacesCreate } from './components/MerchantSpaces/MerchantSpacesCreate/MerchantSpacesCreate';
import { MerchantSpacesEdit } from './components/MerchantSpaces/MerchantSpacesEdit/MerchantSpacesEdit';
import { MerchantSpacesList } from './components/MerchantSpaces/MerchantSpacesList/MerchantSpacesList';
import { MerchantSpacesUsersCreate } from './components/MerchantSpacesUsers/MerchantSpacesUsersCreate/MerchantSpacesUsersCreate';
import { MerchantSpacesUsersList } from './components/MerchantSpacesUsers/MerchantSpacesUsersList/MerchantSpacesUsersList';
import { MerchantSpacesUsersShow } from './components/MerchantSpacesUsers/MerchantSpacesUsersShow';
import {
  MethodLogosCreate,
  MethodLogosEdit,
  MethodLogosList,
  MethodLogosShow,
} from './components/MethodLogos';
import { MethodsCreate, MethodsEdit, MethodsList, MethodsShow } from './components/Methods';
import { OrderConversionList } from './components/Monitoring/OrderConversion/OrderConversionList/OrderConversionList';
import { NotFound } from './components/NotFound';
import { OrderAdjustmentsReportList } from './components/OrderAdjustmentsReport/OrderAdjustmentsReportList';
import OrderMethodsList from './components/OrderMethods';
import { OrdersShow, OrdersListNew } from './components/Orders';
import ProviderReportsList from './components/ProviderReports';
import {
  ProvidersCreate,
  ProvidersEdit,
  ProvidersList,
  ProvidersShow,
} from './components/Providers';
import {
  ProviderWhiteIpAddressesCreate,
  ProviderWhiteIpAddressesEdit,
  ProviderWhiteIpAddressesList,
  ProviderWhiteIpAddressesShow,
} from './components/ProviderWhiteIpAddresses';
import { ForgotPassword, SetNewPassword } from './components/RestorePassword';
import { RolesCreate, RolesEdit, RolesList, RolesShow } from './components/Roles';
import { UsersCreate, UsersEdit, UsersShow, UsersListNew } from './components/Users';
import { APIContext } from './context/apiContext';
import { usePermissions } from './hooks/usePermissions';
import Layout from './layouts/Layout';
import { dataProvider } from './providers/dataProvider';
import { i18nProvider } from './providers/i18nProvider';
import { baseTheme } from './themes/baseTheme';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const introspect = useIntrospection();
  const { user } = useContext(APIContext);
  const isNewCommissionsEnabled = useFlag('wppa-6983-new-commissions');
  const isNewBankOperationsEnabled = useFlag('wppa-7710-new-bank-operations');

  useEffect(() => {
    introspect();
  }, []);

  const { getResourcePropsByPermissions, hasResourcePermissions } = usePermissions();

  return (
    <BrowserRouter>
      <HydraAdmin
        authProvider={authProvider}
        catchAll={NotFound}
        dataProvider={dataProvider}
        disableTelemetry
        entrypoint={API_PATH}
        i18nProvider={i18nProvider}
        layout={Layout}
        loading={Loading}
        loginPage={AppLoginPage}
        queryClient={queryClient}
        store={localStorageStore({
          version: '1',
          appKey: '',
          disabledCacheKeys: [LocalStorageCachedKeys.ListParams],
        })}
        theme={baseTheme}
      >
        {!user?.isLoaded ? (
          <CustomRoutes noLayout>
            <Route element={<ForgotPassword />} path="restore_password" />
            <Route element={<SetNewPassword />} path="new_password/:token" />
          </CustomRoutes>
        ) : (
          <CustomRoutes>
            <Route
              element={
                hasResourcePermissions('monitoring/order_conversion', [
                  Permission.Create,
                  Permission.Full,
                ]) ? (
                  <OrderConversionList />
                ) : (
                  <NotFound />
                )
              }
              path="monitoring/order_conversion"
            />
            <Route
              element={
                hasResourcePermissions('commissions', [Permission.Create, Permission.Full]) ? (
                  <CommissionsCreateNewVersion />
                ) : (
                  <NotFound />
                )
              }
              path="commissions/create-new"
            />
          </CustomRoutes>
        )}
        <Resource
          {...getResourcePropsByPermissions({
            name: 'balance_report',
            create: <></>,
            edit: <></>,
            list: BalanceReportList,
            show: <></>,
          })}
        />
        <Resource
          {...getResourcePropsByPermissions({
            name: 'orders_report',
            create: <></>,
            edit: <></>,
            list: OrdersReportListNew,
            show: <></>,
          })}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'api_clients',
            create: ApiClientsCreate,
            edit: ApiClientsEdit,
            list: ApiClientsList,
            show: ApiClientsShow,
          })}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'merchants',
            create: MerchantsCreate,
            delete: MerchantsDelete,
            edit: MerchantsEdit,
            list: MerchantsList,
            show: MerchantsShow,
          })}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'companies',
            create: CompaniesCreate,
            edit: CompaniesEdit,
            list: CompaniesList,
            show: CompaniesShow,
          }) as DialogResourceProps)}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'methods',
            create: MethodsCreate,
            edit: MethodsEdit,
            list: MethodsList,
            show: MethodsShow,
          })}
        />
        <ResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'method_types',
            create: <></>,
            list: <></>,
          })}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'method_logos',
            create: MethodLogosCreate,
            edit: MethodLogosEdit,
            list: MethodLogosList,
            show: MethodLogosShow,
          }) as DialogResourceProps)}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'work_teams',
            list: WorkTeamsList,
            create: WorkTeamsCreate,
            edit: WorkTeamsEdit,
            show: WorkTeamsShow,
          }) as DialogResourceProps)}
        />
        <ResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'user_actions',
            create: <></>,
            edit: <></>,
            list: UserActionsList,
            show: UserActionsShow,
          })}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'providers',
            create: ProvidersCreate,
            edit: ProvidersEdit,
            list: ProvidersList,
            show: ProvidersShow,
          }) as DialogResourceProps)}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'provider_account_news',
            create: ProviderAccountsCreate,
            edit: ProviderAccountsEdit,
            list: ProviderAccountsList,
            show: ProviderAccountsShow,
          })}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'provider_white_ip_addresses',
            create: ProviderWhiteIpAddressesCreate,
            edit: ProviderWhiteIpAddressesEdit,
            list: ProviderWhiteIpAddressesList,
            show: ProviderWhiteIpAddressesShow,
          }) as DialogResourceProps)}
        />
        {!isNewCommissionsEnabled ? (
          <DialogResource
            {...(getResourcePropsByPermissions({
              name: 'commissions',
              create: CommissionsCreateOld,
              edit: CommissionsEditOld,
              list: CommissionsListOld,
              show: CommissionsShowOld,
            }) as DialogResourceProps)}
          />
        ) : (
          <PageResource
            {...(getResourcePropsByPermissions({
              name: 'commissions',
              create: CommissionsCreate,
              edit: CommissionsEdit,
              list: CommissionsList,
              show: CommissionsShow,
            }) as DialogResourceProps)}
          />
        )}
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'users',
            create: UsersCreate,
            edit: UsersEdit,
            list: UsersListNew,
            show: UsersShow,
          })}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'bank_operations',
            list: isNewBankOperationsEnabled ? BankOperationsListNew : BankOperationsList,
            create: isNewBankOperationsEnabled ? BankOperationsCreateNew : BankOperationsCreate,
            edit: isNewBankOperationsEnabled ? BankOperationsEditNew : BankOperationsEdit,
            show: isNewBankOperationsEnabled ? BankOperationsShowNew : BankOperationsShow,
          })}
        />
        <ResourceGuesser create={<></>} edit={<></>} name="currencies" />
        <ResourceGuesser create={<></>} edit={<></>} name="order_statuses" />
        <ResourceGuesser name="transactions" />
        <ResourceGuesser create={<></>} edit={<></>} name="merchant_notification_settings" />
        <ResourceGuesser create={<></>} edit={<></>} name="transaction_statuses" />
        <ResourceGuesser create={<></>} edit={<></>} name="transaction_types" />
        <ResourceGuesser name="merchant_references" />
        <ResourceGuesser create={<></>} edit={<></>} name="order_action_urls" />
        <ResourceGuesser create={<></>} edit={<></>} name="order_notification_urls" />
        <ResourceGuesser name="order_custom_datas" />
        <Resource
          {...getResourcePropsByPermissions({
            name: 'order_methods',
            create: <></>,
            edit: <></>,
            list: OrderMethodsList,
            show: <></>,
          })}
        />
        <ResourceGuesser name="order_notifications" />
        <ResourceGuesser name="order_events" />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'orders',
            create: <></>,
            edit: <></>,
            list: OrdersListNew,
            show: OrdersShow,
          })}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'order_adjustments',
            create: <></>,
            edit: <></>,
            show: <></>,
            list: OrderAdjustmentsList,
          })}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'gateways',
            create: GatewaysCreate,
            edit: GatewaysEdit,
            list: GatewaysList,
            show: GatewaysShow,
          })}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'gateway_deactivation_reasons',
            list: GatewayDeactivationReasonsList,
            create: GatewayDeactivationReasonsCreate,
            edit: GatewayDeactivationReasonsEdit,
            show: GatewayDeactivationReasonsShow,
          }) as DialogResourceProps)}
        />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'limits',
            create: LimitsCreate,
            edit: LimitsEdit,
            list: LimitsList,
            show: LimitsShow,
          }) as DialogResourceProps)}
        />
        <PageResourceGuesser
          {...getResourcePropsByPermissions({
            name: 'gateway_rules',
            create: GatewayRulesCreate,
            edit: GatewayRulesEdit,
            list: GatewayRulesList,
            show: GatewayRulesShow,
          })}
        />
        <ResourceGuesser name="condition_groups" />
        <ResourceGuesser name="expressions" />
        <ResourceGuesser name="expression_params" />
        <ResourceGuesser name="comparison_operators" />
        <ResourceGuesser name="countries" />
        <ResourceGuesser name="adjustment_statuses" />
        <ResourceGuesser name="adjustment_types" />
        <ResourceGuesser name="commission_statuses" />
        <ResourceGuesser name="commission_comments" />
        <Resource name="new/order_statuses" />
        <DialogResource
          {...(getResourcePropsByPermissions({
            name: 'roles',
            create: RolesCreate,
            edit: RolesEdit,
            list: RolesList,
            show: RolesShow,
          }) as DialogResourceProps)}
        />
        <PageResource
          {...(getResourcePropsByPermissions({
            name: 'providers_report',
            create: <></>,
            edit: <></>,
            list: ProviderReportsList,
            show: <></>,
          }) as any)}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'merchant_spaces_users',
            create: MerchantSpacesUsersCreate,
            edit: MerchantSpacesUsersEdit,
            show: MerchantSpacesUsersShow,
            list: MerchantSpacesUsersList,
          })}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'order_adjustments_report',
            create: <></>,
            edit: <></>,
            show: <></>,
            list: OrderAdjustmentsReportList,
          })}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'orders/bulk_update',
            create: <></>,
            edit: <></>,
            show: <></>,
            list: AppOrdersBulkUpdate,
          })}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'merchant_spaces',
            create: MerchantSpacesCreate,
            edit: MerchantSpacesEdit,
            show: <></>,
            list: MerchantSpacesList,
          })}
        />
        <PageResource
          {...getResourcePropsByPermissions({
            name: 'cumulative_report',
            create: <></>,
            edit: <></>,
            show: <></>,
            list: CumulativeReportListNew,
          })}
        />
      </HydraAdmin>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';

import { Chip } from 'shared/mui/Chips';
import { appColors } from 'themes/variables';
import {
  MerchantAccountBalanceReport,
  MerchantBalanceReport,
  ProviderBalanceReport,
  CompanyBalanceReport,
} from 'types';
import { formatCurrency } from 'utils';

import { AmountDataField } from './BalanceReportAmountField';
import { BalanceReportBoxCell } from './BalanceReportBoxCell';
import { StyledBoxCell, StyledRowBox } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';
import { DatagridActionsCumulativeReportLink } from './BalanceReportDatagrid/BalanceReportDatagridActions/DatagridActionsCumulativeReportLink';
import { DatagridActionsOrdersReportLink } from './BalanceReportDatagrid/BalanceReportDatagridActions/DatagridActionsOrdersReportLink';
import { DatagridActions } from '../../../../shared/react-admin/Datagrids/DatagridActions';
import { useBalanceReportContext } from '../context/BalanceReportContext';

type Props = {
  merchantAccount: MerchantAccountBalanceReport;
  paddingFirstCell: number;
  merchant: MerchantBalanceReport;
  provider: ProviderBalanceReport;
  company: CompanyBalanceReport;
};

export const BalanceReportMerchantAccountRow: React.FC<Props> = ({
  paddingFirstCell,
  merchantAccount,
  provider,
  merchant,
  company,
}) => {
  const { filters } = useBalanceReportContext();

  return (
    <StyledRowBox
      firstCellWidth={392}
      sx={{
        borderBottom: `1px solid ${appColors.divider}`,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.04)',
        },
      }}
    >
      <StyledBoxCell
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: `${paddingFirstCell}px`,
        }}
      >
        <DatagridActions
          sx={{ paddingX: '7px' }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <DatagridActionsOrdersReportLink
            appliedDate={filters.appliedDate}
            company={company}
            merchant={merchant}
            merchantAccount={merchantAccount}
            provider={provider}
          />
          <DatagridActionsCumulativeReportLink
            appliedDate={filters.appliedDate}
            company={company}
            merchant={merchant}
            merchantAccount={merchantAccount}
            provider={provider}
          />
        </DatagridActions>
        <Chip
          color="primary"
          label={merchantAccount.name}
          sx={{ color: 'white', marginLeft: '12px', maxWidth: '212px' }}
        />
      </StyledBoxCell>
      <BalanceReportBoxCell sx={{ textAlign: 'center' }}>
        {merchantAccount.currencyCode}
      </BalanceReportBoxCell>
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedBeginPeriodBalance)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.beginPeriodBalance)}
      />
      <AmountDataField
        color={appColors.success.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedPaymentAmount)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.paymentAmount)}
      />
      <AmountDataField
        color={appColors.error.main}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedPayoutAmount)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.payoutAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedRefundAmount)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.refundAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedChargebackAmount)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.chargebackAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedCommissionAmount)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.commissionAmount)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedTotal)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData?.total)}
      />
      <AmountDataField
        color={appColors.text.dark}
        convertedCurrency={filters?.currency}
        convertedValue={formatCurrency(merchantAccount.amountData.convertedEndPeriodBalance)}
        originalCurrency={merchantAccount.currencyCode}
        originalValue={formatCurrency(merchantAccount.amountData.endPeriodBalance)}
      />
    </StyledRowBox>
  );
};

import { CumulativeReportPeriodDateField } from 'components/Finance/CumulativeReport/components/CumulativeReportDatagrid/components/CumulativeReportPeriodDateField';
import { CumulativeReportFilterValuesNew } from 'components/Finance/CumulativeReport/CumulativeReportFilterNew';
import { useListContext } from 'react-admin';
import { DatagridActions } from 'shared/react-admin/Datagrids/DatagridActions';
import { FunctionField, TextField } from 'shared/react-admin/Fields';
import { CumulativeReport } from 'types';

import { CumulativeReportDatagridSummaryField } from './components/CumulativeReportDatagridSummaryField';
import { CumulativeReportDatagridTotalField } from './components/CumulativeReportDatagridTotalField';
import { CumulativeReportDatagridTransactionsButton } from './components/CumulativeReportDatagridTransactionsButton';
import { DatagridStyled } from './CumulativeReportDatagrid.styled';

type CumulativeReportDatagridProps = {
  filters: CumulativeReportFilterValuesNew;
};

export const CumulativeReportDatagrid: React.FC<CumulativeReportDatagridProps> = ({ filters }) => {
  const { data: cumulativeReport, isLoading } = useListContext();

  return (
    <>
      <DatagridStyled bulkActionButtons={false} data={cumulativeReport} isLoading={isLoading}>
        <FunctionField
          render={(record: CumulativeReport) => {
            const index = cumulativeReport.indexOf(record) + 1;

            return index < 10 ? `0${index}` : index;
          }}
        />
        <FunctionField
          label="Период"
          render={({ periodFrom }: CumulativeReport) => (
            <CumulativeReportPeriodDateField periodFrom={periodFrom} timezone={filters.timezone} />
          )}
          source="periodFrom"
        />
        <TextField fontWeight={500} label="Валюта счета" source="currencyCode" />
        <CumulativeReportDatagridSummaryField
          color="green"
          label="Пополнения"
          source="paymentAmount"
          valueKey="paymentAmount"
        />
        <CumulativeReportDatagridSummaryField
          color="red"
          label="Выводы"
          source="payoutAmount"
          valueKey="payoutAmount"
        />
        <CumulativeReportDatagridSummaryField
          label="Рефанды"
          source="refundAmount"
          valueKey="refundAmount"
        />

        <CumulativeReportDatagridSummaryField
          label="Чарджбеки"
          source="chargeback"
          valueKey="chargeback"
        />
        <CumulativeReportDatagridSummaryField
          label="Комиссия"
          source="commissionValue"
          valueKey="commissionValue"
        />
        <CumulativeReportDatagridTotalField
          label="Итого за период"
          source="totalAmount"
          valueKey="totalAmount"
        />
        <CumulativeReportDatagridSummaryField
          label="Комиссия"
          source="commissionValue"
          valueKey="commissionValue"
        />
        <CumulativeReportDatagridSummaryField
          label="Нарастающий итог"
          source="cumulativeTotalAmount"
          valueKey="cumulativeTotalAmount"
        />
        <DatagridActions
          render={(record: CumulativeReport) => (
            <CumulativeReportDatagridTransactionsButton filters={filters} record={record} />
          )}
        />
      </DatagridStyled>
    </>
  );
};

import { EMPTY_FIELD } from 'constants/emptyField';

import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { CumulativeReport } from 'types';
import { formatCurrency } from 'utils';

import { COLORS, Colors } from '../constants/colors';

type Props = {
  color?: Colors;
  valueKey: keyof CumulativeReport;
} & Omit<FunctionFieldProps, 'render'>;

export const CumulativeReportDatagridSummaryField: React.FC<Props> = ({
  color = 'default',
  label,
  valueKey,
}) => (
  <FunctionField
    label={label}
    render={(record: CumulativeReport) => {
      if (Number(record[valueKey]) === 0) {
        return EMPTY_FIELD;
      }

      return (
        <Stack direction="column">
          <span style={{ color: COLORS[color] }}>
            {formatCurrency(Number(record[valueKey]), 'en')}
          </span>
        </Stack>
      );
    }}
  />
);

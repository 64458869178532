const formats = {
  ru: 'ru-RU',
  en: 'en-US',
};

export function formatCurrency(number: number, format: keyof typeof formats = 'ru') {
  return new Intl.NumberFormat(format, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

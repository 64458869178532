import { useEffect } from 'react';

import { Stack } from '@mui/material';
import { required, number, minValue } from 'react-admin';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from 'shared/mui/DatePicker/DatePicker/DatePicker';
import { Typography } from 'shared/mui/Typography';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { appColors } from 'themes/variables';
import { Currency } from 'types';

export const BankOperationsFormNew: React.FC = () => {
  const { control, setValue, watch } = useFormContext();
  const [merchant, provider, merchantAccount, dateOfOperation] = watch([
    'merchant',
    'provider',
    'merchantAccount',
    'dateOfOperation',
  ]);

  useEffect(() => {
    if (dateOfOperation) {
      setValue('dateOfOperation', Date.parse(dateOfOperation));
    }
  }, []);

  return (
    <form noValidate>
      <Stack direction="row" spacing={2} width="100%">
        <ReferenceInput reference="merchants" source="merchant">
          <AutocompleteInputShrink
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            label="Мерчант"
            optionText="name"
            size="medium"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
        <ReferenceInput
          filter={{ merchantAccounts: { merchants: merchant } }}
          perPage={200}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            disabled={!merchant}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            label="Провайдер"
            optionText="name"
            size="medium"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
      </Stack>
      <ReferenceInput reference="directions" source="direction">
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          label="Направлениe"
          optionText="description"
          size="medium"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput reference="bank_operation_types" source="type">
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          label="Тип банковской операции"
          optionText="description"
          size="medium"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <Stack direction="row" spacing={2} width="100%">
        <ReferenceInput
          enableGetChoices={() => !!provider}
          filter={{ merchants: merchant, provider: provider }}
          reference="merchant_accounts"
          source="merchantAccount"
        >
          <AutocompleteInputShrink
            clearOnBlur={false}
            disabled={!provider}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            label="Счет мерчанта"
            onChange={(_, record: any) => {
              setValue('currency', record?.currency);
            }}
            optionText="name"
            size="medium"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
        {!merchantAccount ? (
          <Typography color={appColors.text.secondary} fontSize={14} lineHeight="16px">
            Значение валюты автоматически принимает значение выбранного счета
          </Typography>
        ) : (
          <ReferenceInput name="currency" reference="currencies" source="currency">
            <AutocompleteInputShrink
              fullWidth
              label="Валюта"
              optionText={(record: Currency) => `${record.alphaCode}, ${record.name}`}
              readOnly
              size="medium"
              variant="outlined"
            />
          </ReferenceInput>
        )}
      </Stack>
      <Stack direction="row" spacing={2} width="100%">
        <TextInputShrink
          label="Сумма"
          size="medium"
          source="amountPenny"
          validate={[
            required(),
            number('Введите число. Например 5 или 0.5'),
            minValue(Number.MIN_VALUE, 'Значение должно быть больше нуля'),
          ]}
          variant="outlined"
        />
        <Controller
          control={control}
          name="dateOfOperation"
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
            formState: { errors },
          }) => (
            <DatePicker
              error={!!errors.dateOfOperation}
              helperText={error?.message}
              label="Дата"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              required
              size="medium"
              value={value || null}
            />
          )}
          rules={{
            required: 'Обязательно для заполнения',
          }}
        />
      </Stack>
      <TextInputShrink
        fullWidth
        label="Описание"
        multiline
        size="medium"
        source="description"
        variant="outlined"
      />
    </form>
  );
};

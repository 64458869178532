import * as React from 'react';

import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { Button, DeleteButton, PageEditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowPage } from 'shared/react-admin/Pages';
import { CommissionsShowTable } from 'shared/widgets/CommissionsShowTable/CommissionsShowTable';
import { Commission, Permission } from 'types';

import { CommissionsShowAgreeDialog } from './CommissionsShowAgreeDialog/CommissionsShowAgreeDialog';
import { CommissionsShowArchivedDialog } from './CommissionsShowArchivedDialog/CommissionsShowArchivedDialog';
import { CommissionsShowArchivedErrorDialog } from './CommissionsShowArchivedErrorDialog/CommissionsShowArchivedErrorDialog';
import { CommissionsShowByAgreementDialog } from './CommissionsShowByAgreementDialog/CommissionsShowByAgreementDialog';
import { CommissionsShowCancelDialog } from './CommissionsShowCancelDialog/CommissionsShowCancelDialog';
import { CommissionsShowImplementDialog } from './CommissionsShowImplementDialog/CommissionsShowImplementDialog';
import { CommissionsShowImplementErrorDialog } from './CommissionsShowImplementErrorDialog/CommissionsShowImplementErrorDialog';
import { CommissionsShowRejectDialog } from './CommissionsShowRejectDialog/CommissionsShowRejectDialog';
import { CommissionStatuses } from '../../../constants/commissionStatuses';
import { Role } from '../../../constants/roles';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { getLastUrlSegment } from '../../../utils/getLastUrlSegment';
import { CommissionsDeleteDialog } from '../components/CommissionsDeleteDialog';
import { useCommissionsAvailableActions } from '../hooks/useCommissionsAvailableActions';
export const CommissionsShow: React.FC = () => {
  const { hasResourcePermissions, hasResourceAccess, roles } = usePermissions();
  const { record } = useShowController();
  const { data = [] } = useCommissionsAvailableActions(record?.originId);

  const {
    handleOpenRecordModal: handleOpenModalDelete,
    openRecordModal: openModalDelete,
    handleCloseRecordModal: handleCloseModalDelete,
  } = useRecordModal<Commission>();

  const {
    record: recordModalAgreement,
    handleOpenRecordModal: handleOpenModalAgreement,
    openRecordModal: openModalAgreement,
    handleCloseRecordModal: handleCloseModalAgreement,
  } = useRecordModal<Commission>();

  const {
    record: recordModalAgree,
    handleOpenRecordModal: handleOpenModalAgree,
    openRecordModal: openModalAgree,
    handleCloseRecordModal: handleCloseModalAgree,
  } = useRecordModal<Commission>();

  const {
    record: recordModalReject,
    handleOpenRecordModal: handleOpenModalReject,
    openRecordModal: openModalReject,
    handleCloseRecordModal: handleCloseModalReject,
  } = useRecordModal<Commission>();

  const {
    record: recordModalImplement,
    handleOpenRecordModal: handleOpenModalImplement,
    openRecordModal: openModalImplement,
    handleCloseRecordModal: handleCloseModalImplement,
  } = useRecordModal<Commission>();

  const {
    record: recordModalCancel,
    handleOpenRecordModal: handleOpenModalCancel,
    openRecordModal: openModalCancel,
    handleCloseRecordModal: handleCloseModalCancel,
  } = useRecordModal<Commission>();

  const {
    record: recordModalArchived,
    handleOpenRecordModal: handleOpenModalArchived,
    openRecordModal: openModalArchived,
    handleCloseRecordModal: handleCloseModalArchived,
  } = useRecordModal<Commission>();

  const {
    record: recordModalImplementError,
    handleOpenRecordModal: handleOpenModalImplementError,
    openRecordModal: openModalImplementError,
    handleCloseRecordModal: handleCloseModalImplementError,
  } = useRecordModal<Commission>();

  const {
    record: recordModalArchivedError,
    handleOpenRecordModal: handleOpenModalArchivedError,
    openRecordModal: openModalArchivedError,
    handleCloseRecordModal: handleCloseModalArchivedError,
  } = useRecordModal<Commission>();

  const renderActions = (): React.ReactElement[] | false => {
    const actions = [];
    const isArchived = data?.length === 0;

    if (record?.parent && isArchived) {
      return false;
    }

    if (
      !data.includes(CommissionStatuses.Implemented) &&
      (roles.includes(Role.Payment) || roles.includes(Role.PaymentLead))
    ) {
      actions.push(
        <Button
          color="primary"
          key="btn-implement-ex"
          label="Внедрить"
          onClick={() => handleOpenModalImplementError(record)}
          variant="contained"
        />,
      );
    }

    if (
      !data.includes(CommissionStatuses.Archived) &&
      (roles.includes(Role.Payment) || roles.includes(Role.PaymentLead))
    ) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Архивировать"
          onClick={() => handleOpenModalArchivedError(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.OnApproval)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agreement"
          label="На согласование"
          onClick={() => handleOpenModalAgreement(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Approved)) {
      actions.push(
        <Button
          color="primary"
          key="btn-agree"
          label="Согласовать"
          onClick={() => handleOpenModalAgree(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Rejected)) {
      actions.push(
        <Button
          color="error"
          key="btn-reject"
          label="Отклонить"
          onClick={() => handleOpenModalReject(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Cancelled)) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Аннулировать"
          onClick={() => handleOpenModalCancel(record)}
          variant="outlined"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Implemented)) {
      actions.push(
        <Button
          color="primary"
          key="btn-implement"
          label="Внедрить"
          onClick={() => handleOpenModalImplement(record)}
          variant="contained"
        />,
      );
    }

    if (data.includes(CommissionStatuses.Archived)) {
      actions.push(
        <Button
          color="error"
          key="btn-cancel"
          label="Архивировать"
          onClick={() => handleOpenModalArchived(record)}
          variant="outlined"
        />,
      );
    }

    return actions;
  };

  const isShowButton = record?.status
    ? [CommissionStatuses.Created, CommissionStatuses.Rejected].includes(
        getLastUrlSegment(record?.status) as CommissionStatuses,
      )
    : false;

  return (
    <ShowPage
      actions={
        hasResourceAccess('user_actions')
          ? [<UserActionsIconButton entityName="commission" key="user-action-link" />]
          : undefined
      }
      additionalActions={renderActions()}
      listName="Комиссии"
      title="Просмотр комиссии"
    >
      <CommissionsShowTable />

      <CardActionsButtons
        leftActionsSlot={
          isShowButton &&
          hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) ? (
            <PageEditButton />
          ) : undefined
        }
        rightActionsSlot={
          isShowButton &&
          hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) ? (
            <DeleteButton onClick={() => handleOpenModalDelete()} variant="outlined" />
          ) : undefined
        }
      />
      {openModalDelete && (
        <CommissionsDeleteDialog
          onClose={handleCloseModalDelete}
          open={openModalDelete}
          record={record}
        />
      )}
      <CommissionsShowByAgreementDialog
        onClose={handleCloseModalAgreement}
        open={openModalAgreement}
        record={recordModalAgreement}
      />
      <CommissionsShowAgreeDialog
        onClose={handleCloseModalAgree}
        open={openModalAgree}
        record={recordModalAgree}
      />
      <CommissionsShowRejectDialog
        onClose={handleCloseModalReject}
        open={openModalReject}
        record={recordModalReject}
      />
      <CommissionsShowImplementDialog
        onClose={handleCloseModalImplement}
        open={openModalImplement}
        record={recordModalImplement}
      />
      <CommissionsShowCancelDialog
        onClose={handleCloseModalCancel}
        open={openModalCancel}
        record={recordModalCancel}
      />
      <CommissionsShowArchivedDialog
        onClose={handleCloseModalArchived}
        open={openModalArchived}
        record={recordModalArchived}
      />
      <CommissionsShowImplementErrorDialog
        onClose={handleCloseModalImplementError}
        open={openModalImplementError}
        record={recordModalImplementError}
      />
      <CommissionsShowArchivedErrorDialog
        onClose={handleCloseModalArchivedError}
        open={openModalArchivedError}
        record={recordModalArchivedError}
      />
    </ShowPage>
  );
};

import { styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { ListItemText } from 'shared/mui/List';
import { DatagridWithStickyActions } from 'shared/react-admin/Datagrids/DatagridWithStickyActions/DatagridWithStickyActions';
import { appColors } from 'themes/variables';
import { getColumnStyle } from 'utils';

export const DatagridStyled = styled(DatagridWithStickyActions)({
  width: '100%',
  borderBottom: `1px solid ${appColors.divider}`,
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderTop: 'none',
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  '& .MuiTableCell-root': {
    verticalAlign: 'middle!important',
  },
  '& .RaDatagrid-headerCell': {
    padding: '10px 16px',
  },
  '& .MuiTableCell-body': {
    verticalAlign: 'top',
    padding: '16px',
  },
  '& .MuiTableCell-head': {
    backgroundColor: '#FAFAFA',
  },
  '& .column-periodFrom': getColumnStyle(120),
  '& .column-currencyCode': getColumnStyle(88),
  '& .column-paymentAmount, .column-payoutAmount, .column-refundAmount, .column-chargeback, .column-commissionValue, .column-cumulativeTotalAmount, .column-totalAmount':
    {
      ...getColumnStyle(168),
      textAlign: 'right',
    },
});

export const StyledCumulativeReportPeriod = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${appColors.divider}`,
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
  borderBottomRightRadius: isOpen ? 0 : '4px',
  borderBottomLeftRadius: isOpen ? 0 : '4px',
  padding: '0 16px',
  backgroundColor: appColors.background,
}));

export const ListItemTextStyled = styled(ListItemText)({
  '.MuiListItemText-primary': {
    fontSize: '14px',
  },
  textDecoration: 'none',
});

export const LinkStyled = styled(Link)({
  textDecoration: 'none',
});

export const BoxStyled = styled(Box)({
  marginBottom: '16px',
});

import { useRecordModal } from 'hooks/useRecordModal';
import { useListContext } from 'react-admin';
import { TextField } from 'shared/react-admin/Fields';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { MerchantAccount } from 'types';

import { DatagridStyled } from './CumulativeReportSummary.styled';
import { CumulativeReportSummaryField } from './CumulativeReportSummaryField';
import { CumulativeReportSummaryMerchantAccountField } from './CumulativeReportSummaryMerchantAccountField';

export const CumulativeReportSummaryDatagrid = () => {
  const { data: cumulativeReportSummary, isLoading } = useListContext();
  const {
    record: merchantAccount,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
    handleOpenRecordModal: handleOpenShowDialog,
  } = useRecordModal<MerchantAccount>();

  return (
    <>
      <DatagridStyled
        bulkActionButtons={false}
        data={cumulativeReportSummary}
        isLoading={isLoading}
      >
        <CumulativeReportSummaryMerchantAccountField
          label="Счет мёрчанта"
          onClick={handleOpenShowDialog}
          source="merchantAccountName"
        />
        <TextField fontWeight={500} label="Валюта счета" source="merchantAccountCurrencyCode" />
        <CumulativeReportSummaryField
          label="Пополнения"
          originalValueKey="originalPaymentAmount"
          source="originalPaymentAmount"
          summaryColor={appColors.success.main}
          valueKey="paymentAmount"
        />
        <CumulativeReportSummaryField
          label="Выводы"
          originalValueKey="originalPayoutAmount"
          source="originalPayoutAmount"
          summaryColor={appColors.error.main}
          valueKey="payoutAmount"
        />
        <CumulativeReportSummaryField
          label="Рефанды"
          originalValueKey="originalRefundAmount"
          source="originalRefundAmount"
          valueKey="refundAmount"
        />
        <CumulativeReportSummaryField
          label="Чарджбеки"
          originalValueKey="originalChargebackAmount"
          source="originalChargebackAmount"
          valueKey="chargebackAmount"
        />
        <CumulativeReportSummaryField
          label="Комиссия"
          originalValueKey="originalCommissionValue"
          source="originalCommissionValue"
          valueKey="commissionValue"
        />
        <CumulativeReportSummaryField
          label="Итого за период"
          originalValueKey="originalTotalAmount"
          source="originalTotalAmount"
          valueKey="totalAmount"
        />
      </DatagridStyled>
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </>
  );
};

import { useState } from 'react';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { BulkActionProps, useResourceContext, useUnselectAll } from 'react-admin';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { BaseDialog } from 'shared/mui/Dialogs';
import { getId } from 'utils';

import { useBulkDeleteBankOperations } from '../../../hooks/useBulkDeleteBankOperations';

export const BankOperationsBulkActionButtons: React.FC<BulkActionProps> = ({ selectedIds }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ids = selectedIds?.map((order) => Number(getId(order))) || [];
  const { bulkDeleteBankOperations } = useBulkDeleteBankOperations();
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);

  return (
    <>
      <Button
        color="error"
        label="Удалить"
        onClick={() => setIsOpen(true)}
        startIcon={<RemoveCircleOutlineIcon />}
      />
      <BaseDialog
        message={`Подтвердите удаление ${selectedIds?.length} банковских операций.`}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        rightActionsSlot={[
          <CancelButton
            key="cancel-button"
            label="Отменить"
            onClick={() => setIsOpen(false)}
            variant="outlined"
          />,
          <Button
            color="error"
            key="ok-button"
            label="Подтвердить"
            onClick={() => {
              bulkDeleteBankOperations.mutate({ ids });
              unselectAll();
              setIsOpen(false);
            }}
            variant="contained"
          />,
        ]}
        title="Удаление банковской операции"
      />
    </>
  );
};

import React from 'react';

import { BoxProps, Typography } from '@mui/material';

import { StyledBoxCell } from './BalanceReportDatagrid/BalanceReportDatagrid.styled';

type Props = {
  header?: boolean;
  alignLeft?: boolean;
} & BoxProps;

export const BalanceReportBoxCell: React.FC<Props> = ({ sx, children, header, alignLeft }) => {
  return (
    <StyledBoxCell sx={{ marginLeft: alignLeft ? 'auto' : 0, ...sx }}>
      <Typography component="span" fontWeight={header ? 500 : 400} variant="body2">
        {children}
      </Typography>
    </StyledBoxCell>
  );
};

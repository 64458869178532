import { BOOLEAN_CHOICES } from 'constants/booleanChoices';

import { differenceInDays } from 'date-fns';
import { RaRecord, required } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateRangeTimezonePicker } from 'shared/mui/DatePicker/DateRangeTimezonePicker/DateRangeTimezonePicker';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterAutocompleteChip,
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterDateTimeRangeTimezoneChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips/FilterDateTimeRangeTimezoneChip/FilterDateTimeRangeTimezoneChip';
import { AutocompleteInputShrink, AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

export const CumulativeReportListFilterChips: React.FC = () => {
  const { resetField, setValue, watch, control } = useFormContext();
  const { setAppliedFilter, appliedFilters, onReset } = useFilterContext();

  const company = watch('company');
  const merchant = watch('merchant');
  const provider = watch('provider');

  return (
    <>
      <FilterDateTimeRangeTimezoneChip label="Период отчета" name="appliedDate" onDelete={onReset}>
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
            <DateRangeTimezonePicker
              error={!!errors.appliedDate}
              helperText={fieldState?.error?.message}
              label="Период отчета от-до"
              maxDate={new Date()}
              onBlur={onBlur}
              onChange={onChange}
              required
              value={value}
            />
          )}
          rules={{
            validate: {
              required: (value) => (!!value[0] && !!value[1]) || 'Обязательное поле',
              periodError: (value) => {
                const [from, to] = value;
                const intervalInDays = differenceInDays(to, from);
                return intervalInDays < 31;
              },
            },
          }}
        />
      </FilterDateTimeRangeTimezoneChip>
      <FilterTextChip
        getName={(filterValue: boolean) =>
          BOOLEAN_CHOICES.find((v) => filterValue === v.id)?.name || ''
        }
        label="Сводка по счетам"
        name="accountsSummary"
        onDelete={onReset}
      >
        <SelectInputShrink
          choices={BOOLEAN_CHOICES}
          defaultValue={false}
          fullWidth
          helperText={false}
          id="accountsSummary"
          label="Сводка по счетам"
          name="accountsSummary"
          source="accountsSummary"
          validate={required()}
          variant="outlined"
        />
      </FilterTextChip>
      <FilterTextChip label="Валюта конвертации" name="currency" onDelete={onReset}>
        <ReferenceInput
          filter={{ active: true }}
          name="currency"
          perPage={1000}
          reference="currencies"
          resettable
          resource="currencies"
          sort={{ field: 'id', order: 'ASC' }}
          source="currency"
        >
          <AutocompleteInputShrink
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ currency: searchText })}
            helperText={false}
            label="Валюта конвертации"
            name="currency"
            optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
            optionValue="alphaCode"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
      </FilterTextChip>
      <FilterAutocompleteChip
        disabled
        label="Компания"
        name="company"
        onApply={() => {
          setValue('merchant', null);
          setValue('provider', null);
          setValue('merchantAccounts', []);

          setAppliedFilter('merchant', null);
          setAppliedFilter('provider', null);
          setAppliedFilter('merchantAccounts', []);
        }}
        onDelete={onReset}
        onReset={() => {
          setValue('merchant', appliedFilters?.['merchant']);
          setValue('provider', appliedFilters?.['provider']);
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);

          setAppliedFilter('merchant', appliedFilters?.['merchant']);
          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="companies"
      >
        <ReferenceInput
          filter={{ archive: false }}
          perPage={100}
          reference="companies"
          source="company"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Компания"
            limitTags={1}
            name="company"
            optionText="name"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Мерчант"
        name="merchant"
        onApply={() => {
          setValue('provider', null);
          setValue('merchantAccounts', []);

          setAppliedFilter('provider', null);
          setAppliedFilter('merchantAccounts', []);
        }}
        onDelete={onReset}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="merchants"
      >
        <ReferenceInput
          enableGetChoices={() => !!company?.length}
          filter={{ companies: company, exists: { merchantAccounts: true }, archive: false }}
          perPage={100}
          reference="merchants"
          source="merchant"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Мерчант"
            limitTags={1}
            name="merchant"
            optionText="name"
            validate={required()}
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Провайдер"
        name="provider"
        onApply={() => {
          setValue('merchantAccounts', []);
          setAppliedFilter('merchantAccounts', []);
        }}
        onDelete={() => {
          resetField('merchantAccounts', { keepDirty: true });
          setAppliedFilter('merchantAccounts', []);
        }}
        onReset={() => {
          setValue('merchantAccounts', appliedFilters?.['merchantAccounts']);
          setAppliedFilter('merchantAccounts', appliedFilters?.['merchantAccounts']);
        }}
        resource="providers"
      >
        <ReferenceInput
          enableGetChoices={() => !!merchant?.length}
          filter={{ merchantAccounts: { merchants: merchant } }}
          name="provider"
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Провайдер"
            limitTags={1}
            name="provider"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Счет мерчанта"
        limitTags={1}
        name="merchantAccounts"
        resource="merchant_accounts"
      >
        <ReferenceArrayInput
          enableGetChoices={() => !!provider}
          filter={{ merchants: merchant, provider }}
          name="merchantAccounts"
          perPage={100}
          reference="merchant_accounts"
          source="merchantAccounts"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Счет мерчанта"
            limitTags={1}
            name="merchantAccounts"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { Button, Popover, TextFieldProps } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useDebounce } from 'hooks/useDebounce';
import { DateObject } from 'react-multi-date-picker';
import { DatePickerTimezoneToolbar } from 'shared/mui/DatePicker/components/DatePickerTimezoneToolbar/DatePickerTimezoneToolbar';
import { fromTimestampToDateObject } from 'shared/mui/DatePicker/utils/fromTimestampToDateObject';
import { isToday } from 'shared/mui/DatePicker/utils/isToday';
import { DAY_END, DAY_START, setDayjs } from 'shared/mui/DatePicker/utils/setDayjs';

import { Calendar } from '../../Calendar/Calendar';
import { DatePickerActions } from '../components/DatePickerActions/DatePickerActions';
import { DatePickerMaskInput } from '../components/DatePickerMaskInput/DatePickerMaskInput';
import { DEFAULT_SHORTCUTS } from '../components/DatePickerShortcuts/constants/shortcuts';
import { DatePickerShortcuts } from '../components/DatePickerShortcuts/DatePickerShortcuts';
import {
  DEFAULT_DATE_MASK,
  DEFAULT_DATE_RANGE_MASK,
  DEFAULT_DATE_RANGE_PLACEHOLDER,
  DEFAULT_SEPARATOR,
  DEFAULT_TIMEZONE,
} from '../config/defaultValues';
import {
  PopoverCalendarContainerStyled,
  PopoverContentStyled,
  PopoverDatesStyled,
  PopoverLayoutStyled,
  PopoverListStyled,
} from '../DatePicker.styled';
import { useAnchor } from '../hooks/useAnchor';
import { formatStringToTimestamp } from '../utils/formatStringToTimestamp';
import { fromTimestampRangeToString } from '../utils/fromTimestampRangeToString';
import { isValidDate } from '../utils/isValidDate';

dayjs.extend(utc);
dayjs.extend(timezone);

export type DateRangePickerValue = number | null;
export type DateRangeTimezoneValue = string | null;

export type DateRangeTimezonePickerProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'defaultValue'
> & {
  minDate?: Date | number;
  maxDate?: Date | number;
  value: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  displayShortcuts?: boolean;
  defaultValue?: [number, number, string];
  onChange?: (
    newValue: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue],
  ) => void;
};

export const DateRangeTimezonePicker: React.FC<DateRangeTimezonePickerProps> = ({
  label,
  variant = 'outlined',
  displayShortcuts = true,
  disabled,
  onChange: onChangeProp,
  minDate,
  maxDate,
  value,
  defaultValue,
  ...restProps
}) => {
  const { handleClose, handleClick, anchorEl } = useAnchor<HTMLDivElement>();
  const [error, setError] = useState(false);
  const [timezoneValue, setTimezoneValue] = useState<DateRangeTimezoneValue>(value[2]);
  const [resetValue, setResetValue] = useState<
    [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue]
  >(value || defaultValue);

  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  const [calendarValue, setCalendarValue] = useState<(DateObject | null)[]>([null, null]);

  const [inputValue, setInputValue] = useState<string | undefined>();

  const open = Boolean(anchorEl);
  const id = open ? 'date-time-picker-popover' : undefined;

  useEffect(() => {
    setResetValue(value);
    if (defaultValue && value.some((el) => !el)) {
      setTimezoneValue(defaultValue[2]);
      setStartTime(dayjs(defaultValue[0]));
      setEndTime(dayjs(defaultValue[1]));
      setInputValue(
        fromTimestampRangeToString(
          [defaultValue[0], defaultValue[1]],
          DEFAULT_DATE_MASK,
          defaultValue[2] || DEFAULT_TIMEZONE,
        ),
      );
      setCalendarValue([
        fromTimestampToDateObject(defaultValue[0], defaultValue[2]),
        fromTimestampToDateObject(defaultValue[1], defaultValue[2]),
      ]);
      return;
    }

    setTimezoneValue(value[2]);

    if (value[0] || value[1]) {
      setStartTime(dayjs(value[0])?.tz(value[2] || DEFAULT_TIMEZONE));
      setEndTime(dayjs(value[1])?.tz(value[2] || DEFAULT_TIMEZONE));
      setCalendarValue([
        fromTimestampToDateObject(value[0], value[2]),
        fromTimestampToDateObject(value[1], value[2]),
      ]);

      setInputValue(
        fromTimestampRangeToString(
          [value[0], value[1]],
          DEFAULT_DATE_MASK,
          value[2] || DEFAULT_TIMEZONE,
        ),
      );
      return;
    }
    setStartTime(null);
    setEndTime(null);
    setCalendarValue([null, null]);
    setInputValue('');
  }, [value]);

  const debouncedOnChangeInputHandler = useDebounce((target: string) => {
    const [start, end] = target.split(DEFAULT_SEPARATOR);

    if (!start && !end) {
      setError(false);
      setInputValue(undefined);
      return;
    }

    const [startDate] = start.split(' ');
    const [endDate] = end.split(' ');

    setError(true);
    const validStartDate = isValidDate(startDate)
      ? new DateObject(formatStringToTimestamp(startDate))
      : null;
    const validEndDate = isValidDate(endDate)
      ? new DateObject(formatStringToTimestamp(endDate))
      : null;

    if (validStartDate && isValidDate(startDate)) {
      const newValue = setDayjs({ timezone: timezoneValue, date: validStartDate, time: DAY_START });
      setError(false);
      setCalendarValue((prev) => [validStartDate, prev[1]]);
      setStartTime(newValue);
    } else {
      setCalendarValue((prev) => [null, prev[1]]);
      setStartTime(null);
    }

    if (validEndDate && isValidDate(endDate)) {
      const newValue = setDayjs({ timezone: timezoneValue, date: validEndDate, time: DAY_END });
      setError(false);
      setCalendarValue((prev) => [prev[0], validEndDate]);
      setEndTime(newValue);
    } else {
      setCalendarValue((prev) => [prev[0], null]);
      setEndTime(null);
    }

    setInputValue(target);
  }, 300);

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    debouncedOnChangeInputHandler(event?.target?.value);
  }

  function onChangeCalendar(value: (DateObject | null)[]) {
    setCalendarValue(value);

    const [start, end] = value;

    const validStart =
      start && startTime
        ? setDayjs({
            timezone: timezoneValue,
            date: start,
            dayjsValue: startTime,
            time: DAY_START,
          })
        : setDayjs({
            timezone: timezoneValue,
            date: start || new DateObject(),
            time: DAY_START,
          });

    let validEnd =
      end && endTime
        ? setDayjs({ timezone: timezoneValue, date: end, dayjsValue: endTime, time: DAY_END })
        : dayjs();

    if (isToday(validEnd)) {
      validEnd = dayjs();
    }

    setStartTime(validStart);
    setEndTime(validEnd);

    const validValue: [DateRangePickerValue, DateRangePickerValue] = [
      validStart.valueOf(),
      validEnd.valueOf(),
    ];

    setInputValue(
      fromTimestampRangeToString(validValue, DEFAULT_DATE_MASK, timezoneValue || DEFAULT_TIMEZONE),
    );
  }

  const onClickShortcut = (value: [DateRangePickerValue, DateRangePickerValue]) => {
    const [startDate, endDate] = value;
    setStartTime(dayjs(startDate).tz(timezoneValue || DEFAULT_TIMEZONE));
    setEndTime(dayjs(endDate).tz(timezoneValue || DEFAULT_TIMEZONE));
    setInputValue(
      fromTimestampRangeToString(value, DEFAULT_DATE_MASK, timezoneValue || DEFAULT_TIMEZONE),
    );
    setCalendarValue([
      fromTimestampToDateObject(value[0], timezoneValue),
      fromTimestampToDateObject(value[1], timezoneValue),
    ]);
  };

  function onClickApply() {
    if (onChangeProp) {
      onChangeProp([startTime?.valueOf() || null, endTime?.valueOf() || null, timezoneValue]);
      setResetValue([startTime?.valueOf() || null, endTime?.valueOf() || null, timezoneValue]);
      handleClose();
      setError(false);
    }
  }

  function onClickReset() {
    if (onChangeProp) {
      onChangeProp(resetValue);
      setTimezoneValue(resetValue[2]);
      setStartTime(resetValue[0] ? dayjs(resetValue[0]) : null);
      setEndTime(resetValue[1] ? dayjs(resetValue[1]) : null);
      setCalendarValue([
        fromTimestampToDateObject(resetValue[0], timezoneValue),
        fromTimestampToDateObject(resetValue[1], timezoneValue),
      ]);
      setInputValue(
        fromTimestampRangeToString(
          [resetValue[0], resetValue[1]],
          DEFAULT_DATE_MASK,
          DEFAULT_TIMEZONE,
        ),
      );
      handleClose();
      setError(false);
    }
  }

  useEffect(() => {
    setError(!!restProps.error);
  }, [restProps.error]);

  const onChangeTimezone = (v: string | null) => {
    setTimezoneValue(v);
    if (startTime) {
      setStartTime(
        setDayjs({
          timezone: v,
          date: calendarValue[0] || new DateObject(),
          time: DAY_START,
        }),
      );
    }
    if (endTime) {
      setEndTime(
        setDayjs({
          timezone: v,
          date: calendarValue[1] || new DateObject(),
          time: DAY_END,
        }),
      );
    }
  };

  const onResetInput = () => {
    if (onChangeProp) {
      onChangeProp([null, null, null]);
      setResetValue(defaultValue || [null, null, null]);
      setError(false);
    }
  };

  return (
    <>
      <DatePickerMaskInput
        {...restProps}
        disabled={disabled}
        error={error || restProps?.error}
        label={label}
        mask={DEFAULT_DATE_RANGE_MASK}
        onChange={onChangeInput}
        onClick={(e) => {
          if (disabled) {
            return;
          }

          handleClick(e);
        }}
        onResetInput={onResetInput}
        placeholder={DEFAULT_DATE_RANGE_PLACEHOLDER}
        value={inputValue}
        variant={variant}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        id={id}
        onClose={() => {
          handleClose();
          onClickReset();
        }}
        open={open}
      >
        <PopoverLayoutStyled>
          <PopoverCalendarContainerStyled>
            {displayShortcuts && (
              <PopoverListStyled>
                <DatePickerShortcuts
                  onClick={onClickShortcut}
                  shortcuts={(timezone) => [DEFAULT_SHORTCUTS(timezone)[0]]}
                  timezone={timezoneValue || DEFAULT_TIMEZONE}
                />
              </PopoverListStyled>
            )}
            <PopoverContentStyled>
              <DatePickerTimezoneToolbar
                end={calendarValue[1]}
                onChangeTimezone={onChangeTimezone}
                start={calendarValue[0]}
                timezone={timezoneValue || DEFAULT_TIMEZONE}
                title="Выберите диапазон дат и время"
              />
              <PopoverDatesStyled>
                <PopoverCalendarContainerStyled>
                  <Calendar
                    gap="30px"
                    maxDate={
                      (maxDate && fromTimestampToDateObject(maxDate, timezoneValue)) || undefined
                    }
                    minDate={
                      (minDate && fromTimestampToDateObject(minDate, timezoneValue)) || undefined
                    }
                    numberOfMonths={2}
                    onChange={onChangeCalendar}
                    range
                    value={calendarValue}
                  />
                </PopoverCalendarContainerStyled>
              </PopoverDatesStyled>
            </PopoverContentStyled>
          </PopoverCalendarContainerStyled>
          <DatePickerActions>
            <Button onClick={onClickReset} variant="outlined">
              Отменить
            </Button>
            <Button onClick={onClickApply} variant="contained">
              Подтвердить
            </Button>
          </DatePickerActions>
        </PopoverLayoutStyled>
      </Popover>
    </>
  );
};

import { EMPTY_FIELD } from 'constants/emptyField';

import { Card, Table, TableBody, TableRow } from '@mui/material';
import { AppReferenceField } from 'components/Common';
import { AppNewShowHeadingTableCell, AppNewShowTableCell } from 'components/Common/Show';
import { OrderDisputeField } from 'components/Orders/components/OrderDispute/OrderDisputeField';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import {
  NumberField,
  TextField,
  FunctionField,
  ReferenceArrayField,
  ShowContextProvider,
  useShowController,
  BooleanField,
  RaRecord,
} from 'react-admin';
import { Typography } from 'shared/mui/Typography';
import { DateTimeField, OrderStatusChipField } from 'shared/react-admin/Fields';
import { ReferenceLink, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { MerchantAccountShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { MerchantAccount, Order } from 'types';

import { useShowLinkByPermission } from '../hooks/useShowLinkByPermission';

type Props = {
  notificationUrl?: string;
};

export const OrdersShowInfo = ({ notificationUrl }: Props) => {
  const orderShowController = useShowController<Order>();
  const { record } = orderShowController;
  const { hasResourceAccess } = usePermissions();
  const editLinkByPermission = useShowLinkByPermission();
  const isWithdrawal = record?.direction.endsWith('payout');

  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  return (
    <Card
      sx={{
        border: '1px solid rgba(0,0,0,0.12)',
        '& .MuiTable-root': {
          borderCollapse: 'unset',
          wordBreak: 'break-word',
        },
      }}
    >
      <Typography
        component="h2"
        fontWeight={500}
        mt={2}
        pb={2}
        pl={3}
        sx={{ borderBottom: '1px solid rgba(0,0,0,0.12)' }}
        variant="h6"
      >
        Информация о заказе
      </Typography>
      <ShowContextProvider value={orderShowController}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableBody>
            <TableRow>
              <AppNewShowHeadingTableCell>ID</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <TextField source="originId" />
              </AppNewShowTableCell>
            </TableRow>
            <TableRow>
              <AppNewShowHeadingTableCell>Время создания</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <DateTimeField label="Время создания" source="createdAt" />
              </AppNewShowTableCell>
            </TableRow>
            <TableRow>
              <AppNewShowHeadingTableCell>Сумма</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <NumberField options={{ minimumFractionDigits: 2 }} source="amount" />
              </AppNewShowTableCell>
            </TableRow>
            {hasResourceAccess('currencies') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Валюта</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField link={false} reference="currencies" source="currency">
                    <FunctionField
                      render={(record: RaRecord | undefined) =>
                        `${record?.alphaCode}, ${record?.name}`
                      }
                    />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('merchants') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Мерчант</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField link={false} reference="merchants" source="merchant">
                    {editLinkByPermission.merchants ? (
                      <ReferenceLink type={editLinkByPermission.merchants}>
                        <TextField source="name" />
                      </ReferenceLink>
                    ) : (
                      <TextField source="name" />
                    )}
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('merchant_accounts') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Счёт</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <ReferenceOneField
                    label="Счёт"
                    reference="merchant_accounts"
                    source="merchantAccount"
                    target="id"
                  >
                    <FunctionField
                      render={(record: MerchantAccount) => (
                        <Typography
                          color={appColors.primary.main}
                          onClick={() => {
                            handleOpenMerchantAccountModal(record);
                          }}
                          variant="body2"
                        >
                          {record.name}
                        </Typography>
                      )}
                      sx={{ cursor: 'pointer' }}
                    />
                  </ReferenceOneField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('gateways') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Шлюз</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField link={false} reference="gateways" source="gateway">
                    {editLinkByPermission.merchants ? (
                      <ReferenceLink type={editLinkByPermission.merchants}>
                        <TextField source="name" />
                      </ReferenceLink>
                    ) : (
                      <TextField source="name" />
                    )}
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('methods') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Метод</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <ReferenceOneField
                    label="Метод"
                    link={false}
                    reference="methods"
                    source="method"
                    target="id"
                  >
                    <ReferenceLink type="show">
                      <TextField source="name" />
                    </ReferenceLink>
                  </ReferenceOneField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('customers') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Клиент</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField link={false} reference="customers" source="customer">
                    <TextField source="customerId" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            <TableRow>
              <AppNewShowHeadingTableCell>Направление</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <AppReferenceField label="Направление" reference="directions" source="direction">
                  <TextField source="description" />
                </AppReferenceField>
              </AppNewShowTableCell>
            </TableRow>
            <TableRow>
              <AppNewShowHeadingTableCell>Статус</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <AppReferenceField link={false} reference="new/order_statuses" source="status">
                  <OrderStatusChipField source="description" />
                </AppReferenceField>
              </AppNewShowTableCell>
            </TableRow>
            <TableRow>
              <AppNewShowHeadingTableCell>Данные клиента</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <AppReferenceField link={false} reference=".well-known/genid" source="account">
                  <TextField source="account" />
                </AppReferenceField>
              </AppNewShowTableCell>
            </TableRow>
            {record?.orderExternalError && (
              <TableRow>
                <AppNewShowHeadingTableCell>Причина отмены</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    link={false}
                    reference="orderExternalError"
                    source="orderExternalError"
                  >
                    <TextField source="reason" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_disputes') && (
              <TableRow>
                <AppNewShowHeadingTableCell>Диспут</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <ReferenceArrayField
                    label="Диспут"
                    reference="order_disputes"
                    sortable={false}
                    source="orderDisputes"
                  >
                    <OrderDisputeField />
                  </ReferenceArrayField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {record?.externalError && (
              <TableRow>
                <AppNewShowHeadingTableCell>Ошибка</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  {hasResourceAccess('externalError') && (
                    <AppReferenceField
                      link={false}
                      reference="externalError"
                      source="externalError"
                    >
                      <FunctionField
                        render={(record: { reason: string; errorCode?: string }) =>
                          `${record.reason} ${record.errorCode ? '(' + record.errorCode + ')' : ''}`
                        }
                      />
                    </AppReferenceField>
                  )}
                </AppNewShowTableCell>
              </TableRow>
            )}
            <TableRow>
              <AppNewShowHeadingTableCell>ID у мерчанта</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <TextField source="merchantReference" />
              </AppNewShowTableCell>
            </TableRow>
            <TableRow>
              <AppNewShowHeadingTableCell>ID у провайдера</AppNewShowHeadingTableCell>
              <AppNewShowTableCell>
                <FunctionField
                  label="ID у провайдера"
                  render={(record: RaRecord | undefined) => {
                    return record?.providerReferenceId ? record.providerReferenceId : EMPTY_FIELD;
                  }}
                />
              </AppNewShowTableCell>
            </TableRow>
            {isWithdrawal && (
              <TableRow>
                <AppNewShowHeadingTableCell>Одобрен</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <BooleanField source="capture" />
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_action_urls') && (
              <TableRow>
                <AppNewShowHeadingTableCell>URL возврата</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    label="URL возврата"
                    link={false}
                    reference="order_action_urls"
                    source="orderActionUrls"
                  >
                    <TextField source="returnUrl" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_action_urls') && (
              <TableRow>
                <AppNewShowHeadingTableCell>URL в процессе</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    link={false}
                    reference="order_action_urls"
                    source="orderActionUrls"
                  >
                    <TextField source="pendingUrl" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_action_urls') && (
              <TableRow>
                <AppNewShowHeadingTableCell>URL успеха</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    link={false}
                    reference="order_action_urls"
                    source="orderActionUrls"
                  >
                    <TextField source="successUrl" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_action_urls') && (
              <TableRow>
                <AppNewShowHeadingTableCell>URL провала</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    link={false}
                    reference="order_action_urls"
                    source="orderActionUrls"
                  >
                    <TextField source="failureUrl" />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
            {hasResourceAccess('order_action_urls') && (
              <TableRow>
                <AppNewShowHeadingTableCell>URL уведомлений</AppNewShowHeadingTableCell>
                <AppNewShowTableCell>
                  <AppReferenceField
                    link={false}
                    reference="order_notification_urls"
                    source="orderNotificationUrl"
                  >
                    <FunctionField
                      render={() => {
                        return notificationUrl; //референс не возвращает notificationUrl
                      }}
                    />
                  </AppReferenceField>
                </AppNewShowTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ShowContextProvider>
      {merchantAccount && (
        <MerchantAccountShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </Card>
  );
};

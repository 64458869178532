import { EMPTY_FIELD } from 'constants/emptyField';

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { formatInTimeZone } from 'date-fns-tz';
import { usePermissions } from 'hooks/usePermissions';
import { RaRecord, useListContext } from 'react-admin';
import { DEFAULT_DATE_MASK, DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { Typography } from 'shared/mui/Typography';
import {
  DatagridActions,
  DatagridActionsDeleteButton,
  DatagridActionsEditButton,
  DatagridActionsShowButton,
} from 'shared/react-admin/Datagrids/DatagridActions';
import {
  DateTimeField,
  FunctionField,
  TextField,
  TextLinkField,
  UserProfileField,
} from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { BankOperation, MerchantAccount, Permission } from 'types';
import { formatCurrency } from 'utils';

import { BankOperationsBulkActionButtons } from './BankOperationsBulkActionButtons/BankOperationsBulkActionButtons';
import { StyledDatagrid } from './BankOperationsDatagrid.styled';
import { Role } from '../../../../../constants/roles';
import { useApiContext } from '../../../../../context/apiContext';
import { IconButtonStyled } from '../../../../../shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField.styled';
import { OrdersReportListFinanceUserAlert } from '../../../OrdersReport/OrdersReportList/components/OrdersReportListFinanceUserAlert';
import { BankOperationStatusChipField } from '../../components/BankOperationStatusChipField/BankOperationStatusChipField';
import { useBankOperationEditable } from '../../hooks/useBankOperationEditable';

type Props = {
  handleOpenDeleteModal: (record: BankOperation) => void;
  handleOpenMerchantAccountModal: (record: MerchantAccount) => void;
  handleOpenRecordModalComment: (record: BankOperation) => void;
};

export const BankOperationsDatagrid: React.FC<Props> = ({
  handleOpenDeleteModal,
  handleOpenMerchantAccountModal,
  handleOpenRecordModalComment,
}) => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const { checkBankOperationEditable } = useBankOperationEditable();
  const { total, filterValues } = useListContext();

  const { user } = useApiContext();
  const isFinanceUser = user?.roles.includes(Role.Finance);
  const showFinanceUserAlert = total === 0 && isFinanceUser && Object.keys(filterValues).length;

  return showFinanceUserAlert ? (
    <OrdersReportListFinanceUserAlert userLastName={user.lastName} userName={user.firstName} />
  ) : (
    <StyledDatagrid
      bulkActionButtons={
        hasResourcePermissions('bank_operations', [Permission.Delete, Permission.Full]) && (
          <BankOperationsBulkActionButtons />
        )
      }
    >
      <TextLinkField
        label="ID"
        resource="bank_operations"
        sortBy="id"
        source="originId"
        type="show"
      />
      <FunctionField
        label="Дата проведения"
        render={({ dateOfOperation }: RaRecord) => {
          if (!dateOfOperation) {
            return EMPTY_FIELD;
          }
          return (
            <Typography variant="body2">{`${formatInTimeZone(dateOfOperation, DEFAULT_TIMEZONE, DEFAULT_DATE_MASK)}`}</Typography>
          );
        }}
        sortBy="dateOfOperation"
        source="dateOfOperation"
      />
      <ReferenceField
        emptyText="—"
        label="Тип операции"
        reference="bank_operation_types"
        source="type"
      >
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField emptyText="—" label="Направление" reference="directions" source="direction">
        <TextField source="description" />
      </ReferenceField>
      <FunctionField
        label="Сумма"
        render={({ amountPenny }: BankOperation) => {
          return formatCurrency(Number(amountPenny));
        }}
        source="amountPenny"
      />
      {hasResourceAccess('currencies') && (
        <ReferenceField label="Валюта" link={false} reference="currencies" source="currency">
          <TextField fontWeight={500} source="alphaCode" />
        </ReferenceField>
      )}
      <FunctionField
        label="Статус"
        render={(record: BankOperation) => {
          return (
            <ReferenceField label="Статус" reference="bank_operation_statuses" source="status">
              <BankOperationStatusChipField source="description">
                {Boolean(record?.comments.length) && (
                  <IconButtonStyled onClick={() => handleOpenRecordModalComment(record)}>
                    <ModeCommentOutlinedIcon />
                  </IconButtonStyled>
                )}
              </BankOperationStatusChipField>
            </ReferenceField>
          );
        }}
      />
      {hasResourceAccess('merchants') && (
        <ReferenceField label="Мерчант" link={false} reference="merchants" source="merchant">
          <TextField source="name" />
        </ReferenceField>
      )}
      {hasResourceAccess('providers') && (
        <ReferenceField label="Провайдер" link={false} reference="providers" source="provider">
          <TextField source="name" />
        </ReferenceField>
      )}
      <ReferenceField label="Счета мерчанта" reference="merchant_accounts" source="merchantAccount">
        <FunctionField
          render={(record: MerchantAccount) => (
            <Typography
              color="secondary"
              onClick={() => handleOpenMerchantAccountModal(record)}
              sx={{ color: appColors.primary.main, cursor: 'pointer', textDecoration: 'underline' }}
              variant="body2"
            >
              {record.name}
            </Typography>
          )}
        />
      </ReferenceField>
      <ReferenceOneField
        label="Автор"
        link={false}
        reference="users"
        sortable={false}
        source="user"
        target="id"
      >
        <FunctionField render={() => <UserProfileField />} />
      </ReferenceOneField>
      <DateTimeField label="Дата создания" source="createdAt" />
      <DateTimeField label="Дата редактир." source="updatedAt" />
      <DatagridActions
        render={(record: BankOperation) => (
          <>
            <DatagridActionsShowButton />
            {hasResourcePermissions('bank_operations', [Permission.Full, Permission.Update]) &&
              checkBankOperationEditable(record as BankOperation) && <DatagridActionsEditButton />}
            {hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) &&
              checkBankOperationEditable(record as BankOperation) && (
                <DatagridActionsDeleteButton onClick={() => handleOpenDeleteModal(record)} />
              )}
          </>
        )}
      />
    </StyledDatagrid>
  );
};

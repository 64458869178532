import { getUnixTime } from 'date-fns';
import { useRecordModal } from 'hooks/useRecordModal';
import { CancelButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { SaveButton } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { BankOperation } from 'types';

import { BankOperationsCreateSuccessDialog } from './BankOperationsCreateSuccessDialog/BankOperationsCreateSuccessDialog';
import { queryClient } from '../../../../App';
import { BankOperationsFormNew } from '../components/BankOperationsFormNew';

export const BankOperationsCreateNew: React.FC = () => {
  const { handleOpenRecordModal, openRecordModal, record, handleCloseRecordModal } =
    useRecordModal<BankOperation>();

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Банковские операции"
      mutationOptions={{
        onError: () => undefined,
        onSuccess: async (data: BankOperation) => {
          await queryClient.invalidateQueries(['bank_operations']);
          handleOpenRecordModal(data);
        },
      }}
      shouldUseNativeValidation={false}
      sx={{ marginTop: 0, padding: 0, width: '550px' }}
      title="Создание банковской операции"
      transform={(data) => ({
        ...data,
        dateOfOperation: getUnixTime(data.dateOfOperation),
      })}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <BankOperationsFormNew />
      </CardContent>
      <BankOperationsCreateSuccessDialog
        onClose={handleCloseRecordModal}
        open={openRecordModal}
        record={record}
      />
    </CreateGuesserPage>
  );
};

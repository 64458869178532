import { OrderAdjustmentsTypes } from 'constants/orderAdjustmentTypes';

export const ORDER_ADJUSTMENTS_ALERT_MESSAGE = {
  [OrderAdjustmentsTypes.OrderAmount]:
    'Данная корректировка доступна для заказов со статусом "Успех", для которых после отправки будет изменена сумма транзакции.',
  [OrderAdjustmentsTypes.StatusToSuccess]:
    'Данная корректировка доступна для заказов со статусом "Провал" и "Просрочен", которые после отправки будут переведены в статус "Успех".',
  [OrderAdjustmentsTypes.StatusToRefund]:
    'Данная корректировка доступна для заказов со статусом "Успех", которые после отправки будут переведены в статус "Рефанд".',
  [OrderAdjustmentsTypes.StatusToChargeback]:
    'Данная корректировка доступна для заказов со статусом "Успех", которые после отправки будут переведены в статус "Чарджбек".',
  [OrderAdjustmentsTypes.StatusToFailure]:
    'Данная корректировка доступна для заказов со статусом "Успех" и "Ожидание", которые после отправки будут переведены в статус "Провал".',
  [OrderAdjustmentsTypes.StatusToCanceled]:
    'Данная корректировка доступна для заказов со статусом "Создан", которые после отправки будет переведены в статус "Отмена".',
};

export const ORDER_IMPACT_TO_BALANCE_ALERT_MESSAGE = [
  'По корректируемым заказам не будет учитываться воздействие на баланс счета у игрока.',
  'По корректируемым заказам должно учитываться воздействие на баланс счета у игрока.',
];

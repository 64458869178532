import { BankOperationStatusesNew } from 'constants/bankOperationStatusesNew';

import { Card } from '@mui/material';
import { queryClient } from 'App';
import { required, useNotify } from 'react-admin';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from 'shared/mui/Box';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs/Dialog/Dialog';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { BankOperation } from 'types';
import { getId } from 'utils/getId';

import { useUpdateBankOperationsStatus } from '../../hooks/useUpdateBankOperationsStatus';

type Props = {
  record: BankOperation | undefined;
} & DialogProps;

type FormValues = {
  comment: string;
};

export const BankOperationsShowAgreeDialog: React.FC<Props> = ({ record, ...restProps }) => {
  const form = useForm<FormValues>();
  const notify = useNotify();

  const { mutate: updateBankOperationsStatus } = useUpdateBankOperationsStatus();

  const onClose = () => {
    form.reset();
    restProps.onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    updateBankOperationsStatus(
      {
        id: getId(record?.id),
        data: {
          status: BankOperationStatusesNew.Approved,
          comment: values?.comment,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries();
          onClose();
        },
        onError: (error: any) => notify(error?.data?.errors[0]?.title, { type: 'error' }),
      },
    );
  };

  return (
    <Dialog {...restProps} fullWidth transitionDuration={0}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card>
            <CardHeader
              actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
              title="Подтвердить запрос на согласование?"
            />
            <CardContent>
              <Typography>
                После подтверждения банковская операция будет исполнена по указанным данным.
                <br />
                <br />
                Перед согласованием обязательно оставьте комментарий.
              </Typography>
              <Box sx={{ marginTop: '24px' }}>
                <TextInputShrink
                  fullWidth={true}
                  helperText={false}
                  label="Комментарий"
                  multiline={true}
                  resettable
                  size="medium"
                  source="comment"
                  validate={required()}
                  variant="outlined"
                />
              </Box>
            </CardContent>
            <CardActionsButtons
              rightActionsSlot={[
                <CancelButton
                  key="back-button"
                  label="Отменить"
                  onClick={onClose}
                  variant="outlined"
                />,
                <Button key="ok-button" label="Подтвердить" type="submit" variant="contained" />,
              ]}
            />
          </Card>
        </form>
      </FormProvider>
    </Dialog>
  );
};

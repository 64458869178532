import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import { EnumAutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { User } from 'types';

export const BankOperationsFilterForm: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const [merchant, provider] = watch(['merchant', 'provider']);

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangePicker
            label="Дата проведения / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <ReferenceArrayInput
        filter={{ exists: { merchantAccounts: true } }}
        isFilter={true}
        name="merchant"
        perPage={100}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Мерчант"
          limitTags={1}
          name="merchant"
          onChange={() => {
            setValue('provider', []);
            setValue('merchantAccount', []);
          }}
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{ merchantAccounts: { merchants: merchant } }}
        isFilter={true}
        name="provider"
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Провайдеры"
          limitTags={1}
          name="provider"
          onChange={() => {
            setValue('merchantAccount', []);
          }}
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{ merchants: merchant, provider }}
        isFilter={true}
        name="merchantAccount"
        perPage={100}
        reference="merchant_accounts"
        source="merchantAccount"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Счета мерчантов"
          limitTags={1}
          name="merchantAccount"
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <ReferenceArrayInput name="type" perPage={200} reference="bank_operation_types" source="type">
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Тип операции"
          limitTags={1}
          name="type"
          optionText="description"
          optionValue="value"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        name="status"
        perPage={200}
        reference="bank_operation_statuses"
        source="status"
      >
        <AutocompleteMultiselectInputShrink
          helperText={false}
          label="Статус"
          limitTags={1}
          name="status"
          optionText="description"
          optionValue="value"
          size="small"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        isFilter={true}
        name="user"
        perPage={200}
        reference="users"
        source="user"
      >
        <AutocompleteMultiselectInputShrink
          filterToQuery={(searchText: string) => ({
            fullName: searchText,
          })}
          helperText={false}
          label="Пользователь"
          name="user"
          optionText="name"
          renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
          size="small"
        />
      </ReferenceArrayInput>
    </>
  );
};

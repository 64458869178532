import { getUnixTime } from 'date-fns';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useEditController } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { CancelButton, DeleteButton } from 'shared/mui/Buttons';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DeleteDialog } from 'shared/mui/Dialogs';
import { SaveButton } from 'shared/react-admin/Buttons';
import { EditGuesserPage } from 'shared/react-admin/Pages';
import { BankOperation, Permission } from 'types';

import { BankOperationsFormNew } from '../components/BankOperationsFormNew';

export const BankOperationsEditNew: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useEditController();
  const navigate = useNavigate();

  const {
    record: bankOperation,
    handleCloseRecordModal,
    openRecordModal,
    handleOpenRecordModal,
  } = useRecordModal<BankOperation>();
  return (
    <EditGuesserPage
      leftActionsSlot={[
        <SaveButton key="save-button" label="Сохранить" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Банковские операции"
      mutationOptions={{ onError: () => undefined }}
      rightActionsSlot={
        hasResourcePermissions('bank_operations', [Permission.Full, Permission.Delete]) ? (
          <DeleteButton onClick={() => handleOpenRecordModal(record)} variant="outlined" />
        ) : undefined
      }
      sx={{ marginTop: 0, padding: 0, width: '550px' }}
      title="Редактирование банковской операции"
      transform={(data) => ({
        ...data,
        dateOfOperation: getUnixTime(data.dateOfOperation),
      })}
    >
      <CardContent sx={{ paddingTop: '0!important', width: '100%' }}>
        <BankOperationsFormNew />
      </CardContent>
      <DeleteDialog
        details="Банковская операция будет удалена без возможности восстановления."
        onClose={handleCloseRecordModal}
        onSuccess={() => navigate(-1)}
        open={openRecordModal}
        record={bankOperation}
        title="Удаление банковской операции"
      />
    </EditGuesserPage>
  );
};

import { BOOLEAN_CHOICES } from 'constants/booleanChoices';
import { ExportTransportTypes } from 'constants/exportFormats';

import { differenceInDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { RaRecord, required } from 'react-admin';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezonePicker } from 'shared/mui/DatePicker/DateRangeTimezonePicker/DateRangeTimezonePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

registerLocale('ru', ru);

export type CumulativeReportFilterValuesNew = {
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  currency?: string | null;
  periodFrom?: number | null;
  periodTo?: number | null;
  company?: string | null;
  merchant?: string | null;
  provider?: string | null;
  merchantAccounts?: string[] | [];
  exportType?: string;
  accountsSummary?: boolean | null;
  jobType?: string;
  notificationTransport?: [ExportTransportTypes.Email];
  timezone?: string;
};

export const CumulativeReportNewFilter: React.FC = () => {
  const { control, formState, watch, setValue } = useFormContext();
  const [currentCompanyValue, currentMerchantValue, currentProviderValue] = watch([
    'company',
    'merchant',
    'provider',
  ]);

  return (
    <>
      <Alert severity="warning">
        Период формирования отчета не должен превышать 31 календарный день!
      </Alert>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value }, formState: { errors }, fieldState }) => (
          <DateRangeTimezonePicker
            error={!!errors.appliedDate}
            helperText={fieldState?.error?.message}
            label="Период отчета от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            required
            value={value}
          />
        )}
        rules={{
          validate: {
            required: (value) => (!!value[0] && !!value[1]) || 'Обязательно для заполнения',
            periodError: (value) => {
              const [from, to] = value;
              const intervalInDays = differenceInDays(to, from);
              return intervalInDays < 31;
            },
          },
        }}
      />
      <SelectInputShrink
        choices={BOOLEAN_CHOICES}
        defaultValue={false}
        fullWidth
        helperText={false}
        id="accountsSummary"
        label="Сводка по счетам"
        name="accountsSummary"
        source="accountsSummary"
        validate={required()}
        variant="outlined"
      />
      <ReferenceInput
        filter={{ active: true }}
        name="currency"
        perPage={1000}
        reference="currencies"
        resettable
        resource="currencies"
        sort={{ field: 'id', order: 'ASC' }}
        source="currency"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта конвертации"
          name="currency"
          optionText={(record: RaRecord) => `${record?.alphaCode}, ${record?.name}`}
          optionValue="alphaCode"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={{ archive: false }}
        name="company"
        perPage={200}
        reference="companies"
        resettable
        resource="companies"
        source="company"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Компания"
          name="company"
          onChange={() => {
            setValue('merchant', null);
            setValue('provider', null);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={{
          companies: currentCompanyValue || '',
          archive: false,
        }}
        name="merchant"
        perPage={200}
        reference="merchants"
        resettable
        resource="merchants"
        source="merchant"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Мерчант"
          name="merchant"
          onChange={() => {
            setValue('provider', null);
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          validate={required()}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={{ merchantAccounts: { merchants: currentMerchantValue } }}
        isFilter={true}
        name="provider"
        perPage={200}
        reference="providers"
        resettable
        resource="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          disabled={!currentMerchantValue}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          name="provider"
          onChange={() => {
            setValue('merchantAccounts', []);
          }}
          optionText="name"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              error={!!formState.errors.provider}
              label="Провайдер"
              variant="outlined"
            />
          )}
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={{
          merchants: currentMerchantValue || '',
          provider: currentProviderValue || '',
        }}
        isFilter={true}
        name="merchantAccounts"
        perPage={200}
        reference="merchant_accounts"
        resettable
        resource="merchant_accounts"
        source="merchantAccounts"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!currentProviderValue}
          helperText={false}
          label="Счет мерчанта"
          limitTags={1}
          name="merchantAccounts"
          optionText="name"
          size="small"
        />
      </ReferenceArrayInput>
    </>
  );
};
